import './css/about.css';

import aboutlg from  './images/about-lg.webp';
import aboutsm from  './images/about-sm.webp';
import LandingImg from './images/landing.webp';



function About(){
    return(
        <>
            <section className='about'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='about-images'>
                                <img loading='lazy' alt='aboutlargeimg' src={aboutlg} className='large-img' />
                                <img  loading='lazy' alt='aboutsmallimg' src={aboutsm} className='small-img' />

                                <div className='number'>
                                    <h3> BEGAN TRIP </h3>
                                    <h4> 2001 </h4>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='about-text'>
                                <h2> Magic Fixer Technical Services LLC  </h2>
                                <h1>  Your ultimate destination for all villa, house, and building management and maintenance solutions. </h1>

                                <p> We understand that managing the upkeep and repairs of your residential and commercial properties can be overwhelming. That's why we're here to take that burden off your shoulders. We believe you have more important tasks to focus on than worrying about the condition of your property. With regular reinvestment in maintenance and alterations, we ensure that your houses and buildings are well-maintained and continue to perform optimally.  </p>

                                <div className='benefits'>
                                    <img  loading='lazy' alt='aboutlandingimg' src={LandingImg}/>
                                    <ul>
                                        <li>
                                            <i class="las la-check-circle"></i>
                                            Expertise in Various Maintenance Areas
                                        </li>

                                        <li>
                                            <i class="las la-check-circle"></i>
                                            Convenient Location
                                        </li>

                                        <li>
                                            <i class="las la-check-circle"></i>
                                            Performance Enhancement
                                        </li>

                                        <li>
                                            <i class="las la-check-circle"></i>
                                            Tailored Services for Your Needs
                                        </li>
                                    </ul>
                                </div>

                                <a href="https://wa.me/+971506771087">
                                    DISCOVER MORE 
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default About;