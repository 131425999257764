import './css/landing.css';

import LandingImg from './images/landing.webp';

function Landing(){
    return(
        <>
            <section className='landing'>
                <div className='landing-content'>
                    <div className='overlay'></div>
                    <img  loading='lazy' src={LandingImg} alt='landingimg'/>

                    <div className='text'>
                        <h2> <i class="las la-grip-lines"></i>  Fixing the Impossible </h2>

                        <h1> Magic fixer's Revolutionary Building Maintenance  Solutions  </h1>

                        <p> Magic Fixer Technical Services LLC is a building maintenance company in the UAE that specializes in plumbing, electrical, HVAC, and construction services. We are dedicated to providing reliable, cost-effective solutions for all your maintenance needs. </p>

                        <a href="https://wa.me/+971506771087"> DISCOVER MORE  <i class="las la-arrow-right"></i></a>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Landing;