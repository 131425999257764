import './css/footer.css';
import Logo from './images/logo.png';

function Footer(){
    return(
        <>
            <footer>
                <div className='container-fluid'>
                    <div className='row'>


                        <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                            <div className='footer-content'>
                                <div className='logo'>
                                    <img  loading='lazy' src={Logo} alt="logo" />
                                </div>

                                <p> Experience the difference Magic Fixer Technical Services can make in your property maintenance journey. Trust us to handle your maintenance needs, so you can enjoy peace of mind and make the most of your valuable time. </p>

                                <h2> We Are Avaiable </h2>
                                <h3> Sun-Thu : 08:00 Am to 6:00 Pm </h3>

                                <div className='social'>
                                    <a href="#">
                                        <i class="lab la-facebook-f"></i>
                                    </a>

                                    <a href="#">
                                        <i class="lab la-instagram"></i>
                                    </a>

                                    <a href="#">
                                        <i class="lab la-twitter"></i>
                                    </a>

                                    <a href="#">
                                        <i class="lab la-linkedin-in"></i>
                                    </a>
                                </div>
                            </div>
                        </div>




                        <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                            <div className='footer-content'>
                                <h1> FAST LINKS </h1>
                                <div className='line'>
                                    <span></span>
                                    <span></span>
                                </div>

                                <ul>
                                    <li>
                                        <a href='#'>
                                            <i class="las la-plus"></i>
                                            Home
                                            
                                        </a>
                                    </li>

                                    <li>
                                        <a href='#'>
                                            <i class="las la-plus"></i>
                                            About Us
                                            
                                        </a>
                                    </li>

                                    <li>
                                        <a href='#'>
                                            <i class="las la-plus"></i>
                                            Contact Us
                                            
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>


                        
                        <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                            <div className='footer-content'>
                                <h1> FAST LINKS </h1>
                                <div className='line'>
                                    <span></span>
                                    <span></span>
                                </div>

                                    <div className='contact-info'>

                                    <div className='info'>
                                        <h4> PHONE NUMBER </h4>
                                        <a href="tel:+971506771087"> <i class="las la-phone"></i> +971 50 677 1087 </a>
                                    </div>

                                    <div className='info'>
                                        <h4> EMAIL ADDRESS </h4>
                                        <a href="mailto:info@magicdxb"> <i class="las la-envelope"></i> info@magicdxb  </a>
                                    </div>

                                    <div className='info'>
                                        <h4> OFFICE LOCATION </h4>
                                        <a href="https://maps.app.goo.gl/M8wvSHuLhDFxZjyKA?g_st=iw"> <i class="las la-map-marker"></i> Ras al khor Industrial Area 3 - Dubai </a>
                                    </div>
                                </div>


                            </div>

                            
                        </div>



                        
                        <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                            <div className='footer-content'>
                                <h1> USE WHATSAPP </h1>
                                <div className='line'>
                                    <span></span>
                                    <span></span>
                                </div>

                                <p>
                                    We're always available to help you with
                                    your building maintenance and repair
                                    needs, no matter the time of day or
                                    Affordable Prices night.
                                </p>

                                <a href="https://wa.me/+971506771087"> 
                                    SEND MESSAGE NOW <i class="las la-arrow-right"></i>
                                </a>


                            </div>

                        </div>







                    </div>
                </div>

                <div className='copy-right'>
                    <h1>  &copy; 2023   <span> Magicfixer </span> All Rights Reserved. </h1>
                </div>
            </footer>
        </>
    )
}

export default Footer;