import './css/chooseus.css';

import ChooseUsImg from './images/chooseus.webp';

function ChooseUs(){
    return(
        <>
            <section className='choose-us'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-5 col-md-5 col-sm-12 col-12'>
                            <div className='choose-img'>
                                <img   loading='lazy' src={ChooseUsImg}  alt='chooseus'/>
                            </div>
                        </div>

                        <div className='col-lg-7 col-md-7 col-sm-12 col-12'>
                            <div className='choose-text'>
                                <h2> Why Choose Us? </h2>

                                <h1> Magicfixer Technical Services is a reliable and trustworthy provider </h1>

                                <div className='benefits'>
                                    <div className='benefit'>

                                        <i class="las la-business-time"></i>
                                        <span></span>
                                    </div>

                                    <div className='benefit'>

                                        <i class="las la-hand-holding-usd"></i>
                                        <span></span>
                                    </div>

                                    <div className='benefit'>
                                        <i class="las la-bookmark"></i>

                                        <span></span>
                                    </div>
                                </div>

                                <div className='benefits-text'>
                                    <div className='benefit-text'>
                                        <h1> 24/7 Availability </h1>
                                        <h3> We're always available to help you with
                                            your building maintenance and repair
                                            needs, no 
                                        </h3>
                                    </div>

                                    <div className='benefit-text'>
                                        <h1> Affordable Prices </h1>
                                        <h3> We offer competitive pricing for
                                            all our services, ensuring that you
                                            get high-quality work without
                                            breaking the bank.
                                        </h3>
                                    </div>


                                    
                                    <div className='benefit-text'>
                                        <h1> Easy Booking </h1>
                                        <h3> Our online booking system is quick
                                            and easy to use, making it simple to
                                            schedule the services you need
                                            whenever you need them.
                                        </h3>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default ChooseUs;