import './css/uppernav.css';

function UpperNav(){
    return(
        <>
            <div className='upper-nav'>
                <div className='container-fluid'>
                    
                    <div className='row'>
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='upper-nav-content'>


                                <div className='contact-info'>
                                    <i class="las la-envelope"></i>
                                    <div className='text'>
                                        <a href="mailto:info@magicdxb.com">
                                            info@magicdxb.com
                                        </a>
                                    </div>
                                </div>


                                
                                <div className='contact-info'>
                                    <i class="las la-phone-volume"></i>
                                    <div className='text'>
                                        <a href="tel:+971506771087">
                                        +971 50 677 1087
                                        </a>
                                    </div>
                                </div>


                            </div>
                        </div>

                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='upper-nav-content'>
                                <h3> Follow Us On: </h3>

                                <ul>
                                    <li>
                                        <a href="#">
                                            <i class="lab la-facebook-f"></i>
                                        </a>
                                    </li>

                                    <li>
                                        <a href="#">
                                            <i class="lab la-instagram"></i>
                                        </a>
                                    </li>


                                    <li>
                                        <a href="#">
                                            <i class="lab la-twitter"></i>
                                        </a>
                                    </li>

                                    <li>
                                        <a href="#">
                                            <i class="lab la-linkedin-in"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default UpperNav;