import './css/contact.css';

import ContactImg from './images/contact.webp';

function Contact(){
    return(
        <>
            <section className='contact'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='contact-text'>
                                <h1> Contact  <span> Magicfixer </span> Now !! </h1>
                                <p> With its commitment to excellence and
                                customer satisfaction, Magicfixer has become
                                a leader in the industry. Whether you need
                                electrical, plumbing, HVAC, or general repair
                                services, you can count on Magicfixer to
                                deliver the best possible results. </p>

                                <img  loading='lazy' src={ContactImg} alt="contactimg" />
                            </div>
                        </div>

                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='contact-form'>
                                <form>
                                    <div className='inputs'>
                                        <div className='input'>
                                            <i class="las la-user"></i>
                                            <input type='text' placeholder='Your Name' required />
                                        </div>

                                        <div className='input'>
                                            <i class="lar la-envelope"></i>
                                            <input type='email' placeholder='Email Address' required />
                                        </div>
                                    </div>

                                    <div className='inputs'>
                                        <div className='input'>
                                            <i class="las la-phone"></i>
                                            <input type='tel' placeholder='Phone Number' required />
                                        </div>

                                        <div className='input'>
                                            <select required>
                                                <option value="Electrical Services">Electrical Services</option>
                                                <option value="Plumbing Services">Plumbing Services</option>
                                                <option value="HVAC Services">HVAC Services</option>
                                                <option value="General Repair Services">General Repair Services</option>
                                                <option value="Renovation">Renovation</option>
                                            </select>
                                        </div>
                                    </div>


                                    
                                    <div className='inputs'>
                                        <div className='input'>
                                            <i class="las la-comment-alt"></i>
                                            <textarea required placeholder='Message'>

                                            </textarea>
                                        </div>

                                    </div>


                                    <button> SEND MESSAGE  <i class="las la-arrow-right"></i></button>

                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default Contact;