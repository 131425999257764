import './css/services.css';

import ServicesImg from './images/services.webp';

import ser1 from './images/ser1.webp';
import ser2 from './images/ser2.webp';
import ser3 from './images/ser3.webp';
import ser4 from './images/ser4.webp';
import ser5 from './images/ser5.webp';



import {Swiper,SwiperSlide} from 'swiper/react';

import 'swiper/css';
import 'swiper/css/effect-cube';

import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import {Autoplay,EffectCube,EffectCoverflow,Navigation,Pagination} from 'swiper';


function Services(){
    return(
        <>
            <section className='services'>
                <div className='container-fluid'>
                    <div className='services-content'>
                        <img  loading='lazy' src={ServicesImg} alt='servicesImg' />

                        <div className='services-header'>
                            <div className='text'>
                                <h2> <i class="las la-grip-lines"></i> OUR SERVICES </h2>

                                <h1> We prioritize customer satisfaction </h1>
                            </div>

                            <div className='buttons'>
                                <button className='prev-btn'>
                                    <i class="las la-arrow-left"></i>
                                </button>

                                <button className='next-btn'>
                                    <i class="las la-arrow-right"></i>
                                </button>
                            </div>


                        </div>


                        <div className='services-slider'>



                            <Swiper  grabCursor={true} slidesPerView={'auto'} navigation={{
                            nextEl: '.next-btn',
                            prevEl: '.prev-btn',
                            }} pagination={true}
                            // autoplay={{
                            //     delay: 4000,
                            //     disableOnInteraction: false,
                            // }}
                            modules={[Autoplay,EffectCoverflow,EffectCube,Navigation,Pagination]}
                            >

                                <SwiperSlide>

                                    <div className='service'>
                                        <img  loading='lazy' src={ser1} alt='serviceoneimage'/>
                                        <h1> Electrical Services </h1>

                                        <p> Magicfixer's electrical services include
                                            installation, repair, and maintenance of all
                                            kinds of electrical systems. Its team of
                                            licensed electricians is trained to handle
                                            everything from simple repairs to complex
                                            installations. 
                                        </p>

                                        <a href="https://wa.me/+971506771087">
                                            Contact Us <i class="las la-arrow-right"></i>
                                        </a>
                                    </div>
                                </SwiperSlide>


                                <SwiperSlide>

                                    <div className='service'>
                                        <img  loading='lazy' alt='servicetwoimage' src={ser2}/>
                                        <h1> Plumbing Services </h1>

                                        <p>Magicfixer's plumbing services cover
                                            everything from leak detection and repair to
                                            complete system installations. Its team of
                                            expert plumbers is equipped with the latest
                                            tools and technology to provide fast and
                                            efficient service.
                                        </p>

                                        <a href="https://wa.me/+971506771087">
                                            Contact Us <i class="las la-arrow-right"></i>
                                        </a>
                                    </div>
                                </SwiperSlide>




                                <SwiperSlide>

                                    <div className='service'>
                                        <img  loading='lazy' alt='servicethreeimg' src={ser3}/>
                                        <h1> HVAC Services </h1>

                                        <p> Magicfixer's HVAC services include
                                            installation, repair, and maintenance of
                                            heating, ventilation, and air conditioning
                                            systems. Its team of certified technicians is
                                            trained to work on all kinds of systems,
                                            including central air conditioning, Chilled
                                            water ductless mini-splits, and heat pumps.
                                        </p>

                                        <a href="https://wa.me/+971506771087">
                                            Contact Us <i class="las la-arrow-right"></i>
                                        </a>
                                    </div>
                                </SwiperSlide>



                                <SwiperSlide>

                                    <div className='service'>
                                        <img  loading='lazy' alt='servicefourimg' src={ser4}/>
                                        <h1> General Repair Services </h1>

                                        <p> addition to its specialized services,
                                            Magicfixer also offers general repair services
                                            for all types of buildings. Its team of skilled
                                            technicians can handle everything from
                                            drywall repair to painting and carpentry work.
                                        </p>

                                        <a href="https://wa.me/+971506771087">
                                            Contact Us <i class="las la-arrow-right"></i>
                                        </a>
                                    </div>
                                </SwiperSlide>



                                <SwiperSlide>

                                    <div className='service'>
                                        <img  loading='lazy' alt='servicefiveimage' src={ser5}/>
                                        <h1> Renovation </h1>

                                        <p>  At Magicfixer's, we offer comprehensive
                                            solutions for all your renovation needs.
                                            Whether you're looking to completely
                                            redesign your building's layout or simply
                                            update fixtures and fittings, our skilled team is
                                            here to assist you. With years of experience in
                                            the industry, we bring expertise and creativity
                                            to every project.
                                        </p>

                                        <a href="https://wa.me/+971506771087">
                                            Contact Us <i class="las la-arrow-right"></i>
                                        </a>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>

                        
                    </div>
                </div>
            </section>
        </>
    )
}

export default Services;