import './css/aboutpage.css';
import About from './About';
import AboutPageImg from './images/aboutpageheader.webp';
import Services from './Services';


import { Link as RouterLink } from 'react-router-dom';


function AboutPage(){
    return(
        <>
            <section className='page-header'>
                <div className='overlay'></div>
                <img  loading='lazy' src={AboutPageImg}  alt='aboutpageimg'/>
                <div className='page-header-content'>
                    <div className='text'>
                        <h1> About </h1>
                        <div className='links'>
                            <RouterLink to="/">
                                Home
                            </RouterLink>
                            <i class="las la-arrow-right"></i>
                            <RouterLink to='/about-us'>
                                About
                            </RouterLink>
                        </div>
                    </div>
                </div>
            </section>

            
            <About />
            <section className='about-page'>
                <div className='container-fluid'>
                    <div className='row'>

                        <div className='col-lg-3 col-md-3 col-sm-6 col-12'>

                            <div className='number'>
                                <div className='icon'>
                                    <i class="las la-laugh-beam"></i>
                                </div>
                                <span className='dot'></span>

                                <h1> 2542 + </h1>
                                <h2> HAPPY CUSTOMERS </h2>
                            </div>

                        </div>

                        <div className='col-lg-3 col-md-3 col-sm-6 col-12'>

                            <div className='number'>
                                <div className='icon'>
                                    <i class="las la-users"></i>
                                </div>
                                <span className='dot'></span>

                                <h1> 563 + </h1>
                                <h2> WORKERS </h2>
                            </div>
                        </div>


                        <div className='col-lg-3 col-md-3 col-sm-6 col-12'>

                            <div className='number'>
                                <div className='icon'>
                                    <i class="las la-tools"></i>
                                </div>
                                <span className='dot'></span>

                                <h1> 13 + </h1>
                                <h2> YEARS OF EXPERIENCE </h2>
                            </div>

                        </div>


                        <div className='col-lg-3 col-md-3 col-sm-6 col-12'>

                            <div className='number'>
                                <div className='icon'>
                                    <i class="las la-list-alt"></i>
                                </div>
                                <span className='dot'></span>

                                <h1> 2149 + </h1>
                                <h2> JOBS FINISHED </h2>
                            </div>

                        </div>


                    </div>
                </div>
            </section>

            <Services />
        </>
    )
}

export default AboutPage;