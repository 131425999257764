import './App.css';
import { Routes, Route } from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';

import UpperNav from './Components/UpperNav';
import Navbar from './Components/Navbar';
import Landing from './Components/Landing';
import About from './Components/About';
import Services from './Components/Services';
import ChooseUs from './Components/ChooseUs';
import Contact from './Components/Contact';
import Footer from './Components/Footer';
import AboutPage from './Components/AboutPage';

import ContactPage from './Components/ContactPage';

function App() {

return (
    <div className="App">
                <UpperNav />
                <Navbar />
            <Routes>

                
                        <Route path="/" element={
                        <>

                            <Landing />
                            <About />
                            <Services />
                            <ChooseUs />
                            <Contact />
                            
                        </>
                    } />


                    <Route path="/about-us" element={
                        <>
                            <AboutPage />
                        </>
                    } />


                    
                    <Route path="/contact-us" element={
                        <>
                            <ContactPage />
                        </>
                    } />


        </Routes>

        <Footer />


    </div>
);
}

export default App;
