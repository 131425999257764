import './css/contactpage.css';

import CotactPageImg from './images/contactpage.webp';

import { Link as RouterLink } from 'react-router-dom';




function ContactPage(){
    return(
        <>
        <section className='page-header'>
                <div className='overlay'></div>
                <img  loading='lazy' src={CotactPageImg}  alt='aboutpageimg'/>
                <div className='page-header-content'>
                    <div className='text'>
                        <h1> Contact Us </h1>
                        <div className='links'>
                            <RouterLink to="/">
                                Home
                            </RouterLink>
                            <i class="las la-arrow-right"></i>
                            <RouterLink to='/contact-us'>
                                Contact
                            </RouterLink>
                        </div>
                    </div>
                </div>
            </section>


            <section className='contact-page'>
                <div className='container-fluid'>
                    <div className='contact-page-header'>
                        <div className='row'>
                            <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                                <a href="https://maps.app.goo.gl/M8wvSHuLhDFxZjyKA?g_st=iw">

                                <div className='contact-info'>

                                    <i class="las la-map-marked"></i>
                                    <h2> OUR OFFICE LOCATION </h2>
                                    <h1> Ras al khor Industrial Area 3 - Dubai  </h1>
                                </div>
                                </a>
                            </div>

                            <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <a href="+971506771087">
                                    <div className='contact-info'>

                                        <i class="las la-phone-volume"></i>
                                        <h2> Have A Question ?  </h2>
                                        <h1> +971 50 677 1087 </h1>
                                    </div>
                                </a>
                            </div>


                            <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                                <a href="mailto:info@magicdxb">
                                    <div className='contact-info'>

                                        <i class="las la-envelope"></i>
                                        <h2> Any Question ? Email Us!  </h2>
                                        <h1> info@magicdxb </h1>
                                    </div>
                                </a>
                            </div>



                        </div>
                    </div>


                    <div className='contact-page-content'>
                        <div className='row'>
                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                <div className='map'>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.635187987985!2d55.3817368!3d25.181793199999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f66dfceab4917%3A0x6b6c83c821899e73!2sAl%20Fahad%20Building%20-%20Ras%20Al%20Khor!5e0!3m2!1sen!2sae!4v1691927049474!5m2!1sen!2sae" width="100%" height="800"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                </div>
                            </div>

                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                <div className='form'>
                                    <h1> Contact  <span> Magicfixer </span> Now !! </h1>
                                    <p> With its commitment to excellence and
                                    customer satisfaction, Magicfixer has become
                                    a leader in the industry. Whether you need
                                    electrical, plumbing, HVAC, or general repair
                                    services, you can count on Magicfixer to
                                    deliver the best possible results. </p>

                                    <form>
                                        <div className='inputs'>
                                            <div className='input'>
                                                <i class="las la-user"></i>
                                                <input type='text' placeholder='Your Name' required />
                                            </div>

                                            <div className='input'>
                                                <i class="lar la-envelope"></i>
                                                <input type='email' placeholder='Email Address' required />
                                            </div>
                                        </div>

                                        <div className='inputs'>
                                            <div className='input'>
                                                <i class="las la-phone"></i>
                                                <input type='tel' placeholder='Phone Number' required />
                                            </div>

                                            <div className='input'>
                                                <select required>
                                                    <option value="Electrical Services">Electrical Services</option>
                                                    <option value="Plumbing Services">Plumbing Services</option>
                                                    <option value="HVAC Services">HVAC Services</option>
                                                    <option value="General Repair Services">General Repair Services</option>
                                                    <option value="Renovation">Renovation</option>
                                                </select>
                                            </div>
                                        </div>


                                        
                                        <div className='inputs'>
                                            <div className='input'>
                                                <i class="las la-comment-alt"></i>
                                                <textarea required placeholder='Message'>

                                                </textarea>
                                            </div>

                                        </div>


                                        <button> SEND MESSAGE  <i class="las la-arrow-right"></i></button>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>



                </div>
            </section>
        </>
    )
}

export default ContactPage